<template lang="pug">
	.input
		input(:type="contents.type", :value="value", :multiple="contents.multiple" @input="processValue" :placeholder="contents.placeholder")
		span {{ contents.label }} é obrigatório
</template>

<script>
import { props } from '@/mixins/component'

export default {
	name: "component-input",
	props: {
		...props,
		value: {
			type: String,
			default: ``
		}
	},
	computed: {
		processedValue: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		},
	},
	methods: {
		processValue(event) {
			if (this.contents.type == `file` || this.contents.type == `image`) {
				if (this.contents.multiple) {
					this.processedValue = []
					event.target.files.forEach(item => this.processedValue.push(item))
				} else {
					this.processedValue = event.target.files[0]
				}
			} else {
				this.processedValue = event.target.value
			}
		}
	},
}
</script>

<style lang="stylus" scoped src="./Input.styl"></style>
